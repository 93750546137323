import '../sass/styles.scss';


/*————————————————————————————————————————————————————*\
    ●❱ ANIMATIONS
\*————————————————————————————————————————————————————*/

let slideUp = (target, duration = 500) => {
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.boxSizing = "border-box";
    target.style.height = target.offsetHeight + "px";
    target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("padding-top");
        target.style.removeProperty("padding-bottom");
        target.style.removeProperty("margin-top");
        target.style.removeProperty("margin-bottom");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
        //alert("!");
    }, duration);
};
let slideDown = (target, duration = 500) => {
    target.style.removeProperty("display");
    let display = window.getComputedStyle(target).display;

    if (display === "none") display = "block";

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = "hidden";
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = "border-box";
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + "ms";
    target.style.height = height + "px";
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    window.setTimeout(() => {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
    }, duration);
};
let slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === "none") {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
};

function toggle_attr_expand(element) {

    if (element.getAttribute("aria-expanded") === "true") {
        element.setAttribute("aria-expanded", "false");
    } else {
        element.setAttribute("aria-expanded", "true");
    }
}


/*————————————————————————————————————————————————————*\
    ●❱ FOOTER
\*————————————————————————————————————————————————————*/

let footer_btns_open = document.querySelectorAll('.footer__btn-open');

footer_btns_open.forEach(function (element) {
    element.addEventListener('click', function (e) {
        e.preventDefault();
        slideToggle(element.parentElement.nextElementSibling);
        toggle_attr_expand(element);
        // console.log(element.parentElement.nextElementSibling);
    })
})

const footer_copyright = document.querySelector('.footer__logo-info p');
const current_year = new Date().getFullYear();
if(footer_copyright)
    footer_copyright.prepend('©' + current_year + " ");

/*————————————————————————————————————————————————————*\
    ●❱ CONTENT
\*————————————————————————————————————————————————————*/

/*————————————————————————————————————————————————————*\
    ●❱ HEADER
\*————————————————————————————————————————————————————*/

const header = document.querySelector('.header__menu');

document.addEventListener("DOMContentLoad", function () {
    if (window.scrollY > 100)
        header.classList.add('is-sticky');
    else
        header.classList.remove('is-sticky');
});

document.addEventListener("scroll", function () {
    if (window.scrollY > 100)
        header.classList.add('is-sticky');
    else
        header.classList.remove('is-sticky');
});

/*  |> FORM SEARCH
——————————————————————————————————————————————————————*/

let btn_open_search = document.querySelector('.form-search__btn-toggle');
let btn_close_search = document.querySelector('.form-search__close');
let form_search = document.querySelector('.form-search');

if (btn_open_search) {
    btn_open_search.addEventListener('click', function (e) {
        e.preventDefault();
        form_search.classList.add('active');
        btn_close_search.classList.add('active');
    })
}

if (btn_close_search) {
    btn_close_search.addEventListener('click', function (e) {
        e.preventDefault();
        form_search.classList.remove('active');
        btn_close_search.classList.remove('active');
    })
}

/*  |> MENU MOBILE
——————————————————————————————————————————————————————*/

const action_btn_mobile = function (e) {
    e.preventDefault();
    slideToggle(e.target.nextElementSibling);
    e.target.classList.toggle('active');
}

function btns_menu_mobile() {
    let btns_menus_mobile = document.querySelectorAll('.main-header_navbar--link.collapsible');

    if (btns_menus_mobile) {
        btns_menus_mobile.forEach(el => {
            el.addEventListener('click', action_btn_mobile);
        })
    }
}

let tc_mediaq = window.matchMedia("(max-width: 1100px)");
let mq_min_responsive_m = window.matchMedia("(min-width: 1101px)");


if (tc_mediaq.matches) {
    btns_menu_mobile();
} else {
    tc_mediaq.onchange = (e) => {
        if (e.matches) {
            btns_menu_mobile();
        }
    };
}

/*  |> ACCESIBILITY IN MENUS
——————————————————————————————————————————————————————*/

// let sub_menus = document.querySelectorAll('.main-header_navbar--item button.collapsible');
let all_links = document.querySelectorAll('.collapsible-content a');
let main_links = document.querySelectorAll('.main-header_navbar--menu > .main-header_navbar--item > .main-header_navbar--link');

const a11y_mainLinks_focus = function (e) {
    e.preventDefault();
    main_links.forEach((el_inner) => {
        el_inner.parentElement.classList.remove('focus');
    });
    e.target.parentElement.classList.add('focus');
}

const a11y_mainLinks_blur = function (e) {
    e.preventDefault();
    setTimeout(function () {
        if (e.target.nextElementSibling) {
            if (!e.target.nextElementSibling.contains(document.activeElement)) {
                e.target.parentElement.classList.remove('focus');
            }
        }
    }, 100)
}
const a11y_allLinks_blur = function (e) {
    let parent_focus = document.querySelector('.main-header_navbar--item.focus');
    setTimeout(function () {
        if (!parent_focus.contains(document.activeElement)) {
            parent_focus.classList.remove('focus');
        }
    }, 100)
}
const a11y_focusMenus_add = function () {

    if (main_links) {
        main_links.forEach(function (el) {
            el.addEventListener('focus', a11y_mainLinks_focus);
            el.addEventListener('blur', a11y_mainLinks_blur);
        })
    }

    if (all_links) {
        all_links.forEach(function (el) {
            el.addEventListener('blur', a11y_allLinks_blur);
        })
    }
}

const a11y_focusMenus_remove = function () {

    if (main_links) {
        main_links.forEach(function (el) {
            el.removeEventListener('focus', a11y_mainLinks_focus);
            el.removeEventListener('blur', a11y_mainLinks_blur);
        })
    }

    if (all_links) {
        all_links.forEach(function (el) {
            el.removeEventListener('blur', a11y_allLinks_blur);
        })
    }
}

if (mq_min_responsive_m.matches) {
    a11y_focusMenus_add();
} else {
    mq_min_responsive_m.onchange = (e) => {
        if (e.matches) {
            a11y_focusMenus_add();
        } else {
            a11y_focusMenus_remove();
        }
    };
}


/*————————————————————————————————————————————————————*\
    ●❱ FREQUENTLY QUESTIONS
\*————————————————————————————————————————————————————*/

const questions_btn = document.querySelectorAll(".accordion__block--question");

if (questions_btn) {
    questions_btn.forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            toggle_attr_expand(el);
            el.classList.toggle("active");
            slideToggle(el.nextElementSibling, 600);
        })
    })
}


/*————————————————————————————————————————————————————*\
    ●❱ VIDEO
\*————————————————————————————————————————————————————*/

let video_btns_play = document.querySelectorAll('.video-btn-play');
let video_items = document.querySelectorAll('.video-container .video');

if (video_btns_play) {
    video_btns_play.forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            let video_container = el.closest('.video-container');
            video_container.classList.add('played');
            video_container.querySelector(":scope .video").play();
        })
    })
}

if (video_items) {
    video_items.forEach(function (el) {
        el.addEventListener('ended', function (e) {
            el.closest('.video-container').classList.remove('played');
        })
    })
}

/*————————————————————————————————————————————————————*\
    ●❱ ACCORDION OFFICES
\*————————————————————————————————————————————————————*/

let foo_bt_btn = document.querySelector('.footer-bt__item--btn');
let foo_bt_details = document.querySelector('.footer-bt .info-bottom .col-12');

if (foo_bt_btn) {
    foo_bt_btn.addEventListener('click', (e) => {
        e.preventDefault();
        slideToggle(foo_bt_details, 600);
        foo_bt_btn.classList.toggle('active');
    })
}

/* Active tab when scrolling */
const links = document.querySelectorAll('.link_tab');
const sections = document.querySelectorAll('.sectionLink');

function giveActive() {
    let len = sections.length;
    if (len != 0) {
        while (--len && window.scrollY + 170 < sections[len].offsetTop) {}
        links.forEach(ltx => ltx.parentElement.classList.remove('active'));
        links.forEach((e, i) => {
            if (links[i].id.slice(1) == sections[len].id) {
                links[i].parentElement.classList.add('active');
            }
        })
    }
}

giveActive();
window.addEventListener('scroll', giveActive);

let btn_header_offices = document.querySelector('#header-offices');
if (btn_header_offices) {
    btn_header_offices.addEventListener('click', function (e) {
        if (!foo_bt_btn.classList.contains('active')) {
            foo_bt_btn.click();
        }
    })
}


let btn_header_offices_mobile = document.querySelector('#header-offices-mobile');
if (btn_header_offices_mobile) {
    btn_header_offices_mobile.addEventListener('click', function (e) {
        document.querySelector('.hamburger').click();
        if (!foo_bt_btn.classList.contains('active')) {
            foo_bt_btn.click();
        }
    })
}


const element = document.querySelectorAll('.hero-page .container');
if (element) {
    element.forEach((i, el) => {

        const margin = element[el].currentStyle || window.getComputedStyle(element[el]);
        document.querySelectorAll('.sc-with-sidebar .container').forEach((element) => {
            element.style.marginLeft = margin.marginLeft;
            // element.style.setProperty('--left', 'calc(-' + margin.marginLeft + ' - 15px)');
        });

        window.addEventListener('resize', () => {
            const margin = element[el].currentStyle || window.getComputedStyle(element[el]);
            document.querySelectorAll('.sc-with-sidebar .container').forEach((element) => {
                element.style.marginLeft = margin.marginLeft;
                // element.style.setProperty('--left', 'calc(-' + margin.marginLeft + ' - 15px)');
            });
        }, true);
    });
}

document.addEventListener('submit', (e) => {

    const form = e.target;
    if (form.classList.contains('ct-form')) {
        // Prevent the default form submit
        e.preventDefault();

        const ele_message = form.querySelector('.ct-form__message')
        const formData = new FormData(form);
        const payload = {};
        formData.forEach(function (value, key) {
            payload[key] = value;
        });

        // Post data using the Fetch API
        fetch(form.action, {
            method: form.method,
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': e.target.csrfmiddlewaretoken.value,
            },
            body: JSON.stringify(payload)
        }).then(response => {
            return response.json() //Convert response to JSON
        }).then(data => {
            if (data.sent) {
                form.querySelector('.ct-form__form-wrapper').style.display = "none";
                ele_message.style.display = "flex";
            }
        }).then(
            setTimeout(() => {
                ele_message.style.display = "none";
                e.target.reset();
                form.querySelector('.ct-form__form-wrapper').style.display = "grid";
            }, 5000)
        )
    }
});

/*————————————————————————————————————————————————————*\
    ●❱ FIX MODAL Z-INDEX
    We move '.modal-container' elements to body element for delete problem with z-indez
\*————————————————————————————————————————————————————*/

let modal_containers = document.querySelectorAll('.modal-container');

if (modal_containers) {
    modal_containers.forEach(el => {
        document.body.appendChild(el);
    })
}

/*————————————————————————————————————————————————————*\
    ●❱ SLIDERS
\*————————————————————————————————————————————————————*/


window.addEventListener('load', () => {

    /*  |> MAIN SLIDER
    ——————————————————————————————————————————————————————*/
    if (document.querySelector(".hero-main .js-slider-container")) {
        let slider_main = tns({
            container: '.hero-main .js-slider-container',
            mode: 'gallery',
            loop: true,
            gutter: 0,
            autoplay: true,
            mouseDrag: true,
            speed: 1000,
            autoplayTimeout: 8000,
            nav: false,
            autoplayButtonOutput: false,
            // preventScrollOnTouch: 'auto',
            items: 1,
        });
    }

    /*  |> INTERNAL SLIDER
        ——————————————————————————————————————————————————————*/

    if (document.querySelector(".inter-slider .js-slider-container")) {
        let slider_inter = tns({
            container: '.inter-slider .js-slider-container',
            mode: 'gallery',
            loop: true,
            gutter: 0,
            // autoplay: true,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButtonOutput: false,
            preventScrollOnTouch: 'auto',
            items: 1,
        });
    }

    /*  |> IMAGES SLIDER
        ——————————————————————————————————————————————————————*/

    if (document.querySelector(".imgs-slider .js-slider-container")) {
        let slider_imgs = tns({
            container: '.imgs-slider .js-slider-container',
            mode: 'gallery',
            loop: true,
            gutter: 0,
            // autoplay: true,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButtonOutput: false,
            preventScrollOnTouch: 'auto',

            items: 1,
        });
    }

    /*  |> REPORTS SLIDER
        ——————————————————————————————————————————————————————*/
    if (document.querySelector(".reports-slider .row")) {
        let slider_reports = tns({
            container: '.reports-slider .row',
            loop: true,
            gutter: 24,
            // autoplay: true,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButtonOutput: false,
            preventScrollOnTouch: 'auto',
            responsive: {
                998: {
                    items: 5,
                },
                576: {
                    items: 4,
                },
                320: {
                    items: 1,
                },
            }
        });
    }

    /*  |> Hiring slider
    ——————————————————————————————————————————————————————*/

    if (document.querySelector(".hiring-slider__slider-wrapper")) {
        let slider_hiring = tns({
            container: '.hiring-slider__slider-wrapper',
            // mode: 'gallery',
            loop: true,
            gutter: 0,
            // autoplay: true,
            mouseDrag: true,
            speed: 600,
            nav: false,
            autoplayButtonOutput: false,
            preventScrollOnTouch: 'auto',
            items: 1,
            responsive: {
                767: {
                    autoHeight: false
                },
                300: {
                    autoHeight: true
                },
            }
        });
    }
})


const menu = document.querySelector('#checkbox');
const divMenu = document.querySelector('.menu');
menu.addEventListener('change', () => {
    if (menu.checked)
        divMenu.classList.add('menu-active');
    else
        divMenu.classList.remove('menu-active');
});

const coll = document.querySelectorAll("collapsible-content");

coll.forEach((e, i) => {
    coll[i].addEventListener("click", function () {
        coll[i].classList.toggle('collapseToggle');
    })
});

/*————————————————————————————————————————————————————*\
    ●❱ HIDDEN LINKS "CONSULTE AQUI" IN MOBILE
\*————————————————————————————————————————————————————*/

let links_consulte = document.querySelectorAll(".main-header_navbar a");

links_consulte.forEach(function (el) {
    if ((el.textContent.trim().toLocaleLowerCase() === 'Consulta aquí'.toLocaleLowerCase()) || (el.textContent.trim().toLocaleLowerCase() === 'Consulte aquí'.toLocaleLowerCase())) {
        el.classList.add('js-consulte-aqui');
    }
})

const items_origin = document.querySelectorAll("[data-popup-origin]");
items_origin.forEach(function (element_origin) {
    element_origin.addEventListener("click", function (e) {
        e.preventDefault();
        const element_dest = document.querySelector('[data-popup-dest="' + element_origin.getAttribute("data-popup-origin") + '"]');

        /* If element_dest is equal to null then we search element via class name */
        if (!element_dest) {
            const element_dest = document.querySelector('[class*="data-popup-dest--' + element_origin.getAttribute("data-popup-origin") + '"]');
        }
        if (element_dest) {
            element_dest.classList.add("show--pop-up");
        }
    });
});
const items_origin_close = document.querySelectorAll(
    "[data-popup-dest] .button-close, [class*='data-popup-dest--'] .button-close, [class*='data-popup-dest--'] .button-close a"
);

items_origin_close.forEach(function (element) {
    element.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelectorAll("[data-popup-dest], [class*='data-popup-dest--']").forEach(function (el) {
            el.classList.remove("show--pop-up");
        });
    });
});

/* Cerrar modal si se hace click fuera de los elementos hijos */

let items_dest = document.querySelectorAll("[data-popup-dest], [class*='data-popup-dest--']");

items_dest.forEach(function (element) {
    element.addEventListener("click", function (e) {
        if (e.target === element) {
            e.preventDefault();
            e.target.classList.remove("show--pop-up");
        }
    });
})


const list_links = document.querySelector('.text_image_content');
const workWus_links = document.querySelector('.work_with_us_section_description');

if (list_links) {
    const node_list = list_links.lastElementChild.children;
    for (let i = 0; i < node_list.length; i++) {
        if (node_list[i].children.length != 0) {
            let nodes = node_list[i].childNodes;
            if (nodes[0].localName == 'a'){
                node_list[i].classList.add('list_class');
            }
        }
    }
}

if (workWus_links) {
    const node_list_work_us = workWus_links.lastElementChild.children;
    for (let i = 0; i < node_list_work_us.length; i++) {
        if (node_list_work_us[i].children.length != 0) {
            node_list_work_us[i].classList.add('list_class');
        }
    }
}

/*————————————————————————————————————————————————————*\
    ●❱ BREADCRUMB STICKY
\*————————————————————————————————————————————————————*/

let global_breadcrumb = document.querySelector('.breadcrumbs__list');
// let breadcrumbs_only_link = document.querySelector('.breadcrumbs--only-link');

if (global_breadcrumb) {
    var clone_breadcrumb = global_breadcrumb.cloneNode(true);
    let tabs_component = document.querySelector('.tabs_list');
    if (clone_breadcrumb) {
        let container_breadcrumb = document.createElement('div');
        let element_hero = document.querySelector('.banner_section') || document.querySelector('.hero-page');
        container_breadcrumb.classList.add('breadcrumbs--sticky-container');
        clone_breadcrumb.classList.add('breadcrumbs--sticky', 'container');
        container_breadcrumb.appendChild(clone_breadcrumb);
        document.body.appendChild(container_breadcrumb);
        var container_breadcrumb_dom = document.querySelector('.breadcrumbs--sticky-container');

        if (window.scrollY > element_hero.offsetHeight + 40) {
            container_breadcrumb_dom.classList.add('active');
        } else {
            container_breadcrumb_dom.classList.remove('active');
        }

        document.addEventListener("scroll", function () {
            if (window.scrollY > element_hero.offsetHeight + 55) {
                container_breadcrumb_dom.classList.add('active');
            } else {
                container_breadcrumb_dom.classList.remove('active');
            }
        });
    }
}

const recaptcha = document.querySelector('.g-recaptcha-response');

if(recaptcha) {
    recaptcha.setAttribute("required", "required");
}